<template>
  <div class="main_container">
    <div class="main_page">
      <div class="page_title">{{ $route.meta.title }}</div>
      <div class="page_containt">
        <div class="page_search">
          <el-form ref="searchForm" :model="searchForm">
            <el-row type="flex">
              <el-col class="search_col">
                <el-form-item size="small" prop="key">
                  <el-input
                    size="small"
                    type="text"
                    v-model="searchForm.key"
                    style="width: 180px"
                    placeholder="ID/姓名"
                  />
                </el-form-item>
              </el-col>
              <el-col class="search_col">
                <el-form-item size="small" prop="begin_at">
                  <el-date-picker
                    size="small"
                    style="width: 150px"
                    v-model="searchForm.begin_at"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="创建开始日期"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col class="search_col" style="line-height: 32px"> 至 </el-col>
              <el-col class="search_col">
                <el-form-item size="small" prop="end_at">
                  <el-date-picker
                    size="small"
                    style="width: 150px"
                    v-model="searchForm.end_at"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="创建结束日期"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col class="search_col">
                <el-button
                  type="primary"
                  size="small"
                  native-type="submit"
                  @click.native.prevent="handleSearch"
                  >搜索</el-button
                >
              </el-col>
              <el-col class="search_col">
                <el-button size="small" @click="resetSearchForm"
                  >重置</el-button
                >
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div class="page_box" ref="pageBox">
          <el-table
            v-loading="loading"
            size="small"
            class="el_table"
            cell-class-name="el_table_cell"
            header-row-class-name="el_table_header"
            :data="listData"
            :height="tableHeight"
            style="width: 100%"
          >
            <el-table-column prop="vip_no" label="ID" width="120">
            </el-table-column>
            <el-table-column label="头像" width="60">
              <template slot-scope="scope">
                <el-avatar
                  :src="scope.row.face_url"
                  v-if="scope.row.face_url"
                  :size="50"
                ></el-avatar>
                <el-avatar
                  icon="el-icon-user-solid"
                  v-else
                  :size="50"
                ></el-avatar>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="姓名" width="150">
            </el-table-column>
            <el-table-column label="标签" min-width="300">
              <template slot-scope="scope">
                <el-tag
                  type="info"
                  v-for="(item, index) in scope.row.label"
                  :key="index"
                  >{{ item }}</el-tag
                >
              </template>
            </el-table-column>
            <el-table-column prop="group_name" label="分组" width="100">
            </el-table-column>
            <el-table-column label="邀请人ID" width="120">
              <template slot-scope="scope">
                {{ scope.row.top_vip_no }}
              </template>
            </el-table-column>
            <el-table-column label="邀请人" width="150">
              <template slot-scope="scope">
                {{ scope.row.top_vip_name }}
              </template>
            </el-table-column>
            <el-table-column prop="look_num" label="观看次数" width="100">
            </el-table-column>
            <el-table-column prop="look_time_name" label="观看时长" width="150">
            </el-table-column>
            <el-table-column prop="logined_count" label="登录次数" width="140">
            </el-table-column>
            <el-table-column prop="logined_at" label="最近登录日期" width="140">
            </el-table-column>
            <el-table-column prop="ip" label="IP" width="120">
            </el-table-column>
            <el-table-column prop="address" label="地区" min-width="150">
            </el-table-column>
            <el-table-column prop="created_at" label="创建日期" width="140">
            </el-table-column>
            <el-table-column
              fixed="right"
              align="right"
              header-align="right"
              label="操作"
              width="150"
            >
              <template slot-scope="scope">
                <el-button
                  class="btn"
                  type="text"
                  icon="el-icon-edit"
                  size="small"
                  @click="doForm(scope.row)"
                >
                  编辑
                </el-button>
                <el-button
                  @click="labelForm(scope.row)"
                  class="btn"
                  type="text"
                  icon="el-icon-edit"
                  size="small"
                >
                  打标签
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="page_pagination">
            <el-pagination
              background
              layout="total,prev,pager,next,jumper"
              @current-change="handleCurrentChange"
              :total="pageConfig.counts"
              :page-size="pageConfig.pageSize"
              :current-page="pageConfig.pageIndex"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <el-drawer
      title="用户标签"
      size="50%"
      :visible.sync="drawer"
      direction="rtl"
      :wrapperClosable="false"
      :close-on-press-escape="false"
    >
      <div class="drawer_box">
        <el-form label-width="80px" size="small">
          <el-form-item label="ID：" size="small">
            {{ model ? model.vip_no : "" }}
          </el-form-item>
          <el-form-item label="用户：" size="small">
            <el-row type="flex" align="middle" v-if="model">
              <el-col style="width: auto">
                <el-avatar :src="model.face_url" :size="40"></el-avatar>
              </el-col>
              <el-col style="width: auto; padding-left: 10px">
                {{ model.name }}
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="标签：" size="small">
            <el-tag
              :key="tag"
              v-for="tag in tagList"
              closable
              :disable-transitions="false"
              @close="handleTagClose(tag)"
            >
              {{ tag }}
            </el-tag>
            <el-input
              class="input-new-tag"
              v-if="inputTagVisible"
              v-model="inputTagValue"
              ref="saveTagInput"
              size="small"
              style="width: 100px"
              @keyup.enter.native="handleTagInputConfirm"
              @blur="handleTagInputConfirm"
            >
            </el-input>
            <el-button
              v-else
              class="button-new-tag"
              size="small"
              @click="showTagInput"
              >+ 新增</el-button
            >
          </el-form-item>
          <div class="drawer_footer" style="left: 50%">
            <el-button
              type="primary"
              size="small"
              native-type="submit"
              @click="handleLabelSubmit"
              :loading="btnLogining"
              >提交</el-button
            >
            <el-button size="small" @click="drawer = false">取消</el-button>
          </div>
        </el-form>
      </div>
    </el-drawer>
    <el-dialog
      title="编辑会员"
      :visible.sync="dialogVisible"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      @close="dialogVisible = false"
      width="400px"
    >
      <el-form
        ref="modelForm"
        :model="modelForm"
        :rules="modelRules"
        label-width="100px"
        size="small"
        class="form_box"
      >
        <el-form-item label="分组：" class="el-form-item-no" prop="group_id">
          <el-select
            v-model="modelForm.group_id"
            size="small"
            popper-class="select"
            style="width: 100px"
            placeholder="请选择"
          >
            <el-option
              v-for="item in groupList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-button
            type="primary"
            native-type="submit"
            style="width: 80px"
            @click.native.prevent="handleSubmit"
            size="small"
            :loading="btnLogining"
            >确定</el-button
          >
          <el-button style="width: 80px" @click="dialogVisible = false"
            >取消</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { onTableHeight } from "@/lib/table";
import vipApi from "@/api/vip";
export default {
  data() {
    return {
      loading: false,
      btnLogining: false,
      drawer: false,
      dialogVisible: false,
      model: null,
      searchForm: {
        key: "",
        begin_at: "",
        end_at: "",
      },
      listData: [],
      tagList: [],
      inputTagVisible: false,
      inputTagValue: "",
      pageConfig: { pageSize: 50, pageIndex: 1, counts: 0 },
      tableHeight: 500,
      groupList: [{
        name:'请选择',
        id:null
      }],
      modelForm: {
        group_id: null,
      },
      modelRules: {
        group_id: [{ required: true, message: "请选择", trigger: "blur" }],
      },
    };
  },
  mounted() {
    onTableHeight(this);
    this.getList();
    this.getGroupList();
  },
  methods: {
    //查询
    handleSearch() {
      this.getList(1);
    },
    //重置
    resetSearchForm() {
      if (this.$refs.searchForm) this.$refs.searchForm.resetFields();
      this.getList(1);
    },
    //分页
    handleCurrentChange(val) {
      this.getList(val);
    },
    getGroupList() {
      vipApi.vipGroupList().then((res) => {
        this.groupList =this.groupList.concat(res.list);
      });
    },
    //列表
    getList(pageIndex = 1) {
      this.loading = true;
      vipApi
        .vipList({
          page_size: this.pageConfig.pageSize,
          page_index: pageIndex,
          ...this.searchForm,
        })
        .then((res) => {
          this.listData = res.list;
          this.pageConfig.pageIndex = res.page.page_index;
          this.pageConfig.counts = res.page.counts;
        })
        .catch((res) => {})
        .finally(() => {
          this.loading = false;
        });
    },
    labelForm(row) {
      this.model = row;
      this.drawer = true;
    },
    handleTagClose(tag) {
      this.tagList.splice(this.tagList.indexOf(tag), 1);
    },

    showTagInput() {
      this.inputTagVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    handleTagInputConfirm() {
      let inputTagValue = this.inputTagValue;
      if (inputTagValue) {
        this.tagList.push(inputTagValue);
      }
      this.inputTagVisible = false;
      this.inputTagValue = "";
    },
    handleLabelSubmit() {
      this.btnLogining = true;
      vipApi
        .vipLabelForm({
          id: this.model ? this.model.id : 0,
          labels: JSON.stringify(this.tagList),
        })
        .then((res) => {
          this.$message({
            message: "提交成功",
            type: "success",
            offset: 60,
          });
          this.getList(this.pageConfig.pageIndex);
          this.drawer = false;
        })
        .catch(() => {})
        .finally(() => {
          this.btnLogining = false;
        });
    },
    doForm(row){
      this.model=row;
      this.dialogVisible=true;
      this.modelForm.group_id=row.vip_group_id!=0?row.vip_group_id:null;
    },
    handleSubmit(){
      this.$refs.modelForm.validate((valid) => {
        if (valid) {
          this.btnLogining = true;
          vipApi
            .vipForm({
              id: this.model.id,
              ...this.modelForm,
            })
            .then((res) => {
              this.$message({
                message: "提交成功",
                type: "success",
                offset: 60,
              });
              this.dialogVisible = false;
              this.getList(this.pageConfig.pageIndex);
            })
            .catch((res) => {})
            .finally(() => {
              this.btnLogining = false;
            });
        }
      });
    }
  },
};
</script>